formly-wrapper-form-field > div {
  @extend .mt-4;
  @extend .mb-3;
}

.form-group {
  @extend .mt-4;
  @extend .mb-3;

  label {
    @extend .fw-bold;
  }

  .invalid-feedback {
    background-color: #fff4e4;
    @extend .p-2;
    font-size: 1rem;
    border-radius: 0.25rem;
    font-weight: 700;
    @extend .mt-2;
  }

  .search-control {
    min-height: 2.8125rem;
    border-radius: 2rem !important;
  }
}

.form-select {
  min-height: 3.5rem;
}

.invalid-feedback {
  background-color: #fff4e4;
  @extend .p-2;
  font-size: 1rem;
  border-radius: 0.25rem;
  font-weight: 700;
  @extend .mt-2;
}

.form-control,
.form-select {
  .was-validated &:invalid,
  &.is-invalid {
    background-color: #fff4e4;
  }

  min-height: 3.5rem;
}

// Pats form overrides
pats-readonly {
  label {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }

  span.form-text {
    margin-top: 0;
    margin-bottom: 0;
  }
}

// pats-select-field .form-group {
//     margin-top: 1.5em !important;
// }

// pats-text-field .form-group {
//     margin-top: 1.5rem !important;
// }

// pats-file-input .form-group,
// pats-drivers-license .form-group,
// pats-h2s-certificate .form-group {
//     margin-top: 1.5em !important;
// }

// .btn-light {
//     color: $primary;
//     background-color: $white;
//     &:hover {
//         color: $primary;
//         background-color: $white;
//         text-decoration: underline;
//     }
//     &:not(:disabled):not(.disabled):active {
//         color: $primary;
//         background-color: $white;
//         text-decoration: underline;
//     }
// }
