@import 'bootstrap/scss/modal';

.modal-filter {
  .modal-dialog {
    @include media-breakpoint-down(xs) {
      position: absolute !important;
      width: 100%;
      margin: unset;
      // bottom: 83px;
      // overflow: scroll;
      // height: 550px;
    }

    .modal-header {
      border: none;
    }

    .modal-body {
    }

    .modal-footer {
      border: none;
    }
  }
}

.modal-phone {
  .modal-dialog {
    .modal-content {
      @extend .bg-secondary;
      @extend .py-5;
    }
  }
}
