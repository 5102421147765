.list-group-item {
  @extend .px-2;
  min-height: 4.56rem;

  &.border-dashed {
    border-style: dashed !important;
  }

  &.border-none {
    border: none !important;
  }

  &.line1-text-muted {
    span {
      @extend .text-muted;
    }
  }
}

.list-group-item-icon {
  @extend .list-group-item;
  @extend .list-group-item-action;
  min-height: 4.8125rem;
}
