.page-container {
  padding-bottom: 190px !important;
}

.full-page-container {
  padding-bottom: 190px !important;
}

.above-finish-route {
  bottom: 175px !important;
}

.above-choose-another-location {
  bottom: 155px !important;
}
