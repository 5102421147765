//Bootstrap
$primary: #2a8afb;
$body-bg: #fff;
$body-color: #363636;
$white-hover: #f2f8ff;
$font-family-base: 'Lato', sans-serif;
$text-muted: inherit;
$font-size-sm: 1rem;
$font-size-md: $font-size-base * 1.125;
$font-size-lg: $font-size-base * 1.25;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-lg;
$h5-font-size: $font-size-md;
$font-weight-bold: 700;
$headings-font-weight: $font-weight-bold;
$btn-font-weight: $font-weight-bold;
$link-decoration: none;
$link-hover-decoration: underline;
$theme-colors: map-merge(
  $theme-colors,
  (
    'high-priority': #f5a734,
  )
);
$theme-colors: map-merge(
  $theme-colors,
  (
    'notification': #f5a734,
  )
);
$theme-colors: map-merge(
  $theme-colors,
  (
    'danger': #f5a734,
  )
);
$theme-colors: map-merge(
  $theme-colors,
  (
    'pause': #ce0b24,
  )
);
$theme-colors: map-merge(
  $theme-colors,
  (
    'red': #ce0b24,
  )
);
$theme-colors: map-merge(
  $theme-colors,
  (
    'pause-100': #ce0b24,
  )
);
$theme-colors: map-merge(
  $theme-colors,
  (
    'delete': #d0021b,
  )
);
$theme-colors: map-merge(
  $theme-colors,
  (
    'active': #7ed63c,
  )
);
$theme-colors: map-merge(
  $theme-colors,
  (
    'active-100': #65ab1b,
  )
);
$theme-colors: map-merge(
  $theme-colors,
  (
    'my-clock': #f5a623,
  )
);
$theme-colors: map-merge(
  $theme-colors,
  (
    'gray': #ededed,
  )
);
$theme-colors: map-merge(
  $theme-colors,
  (
    'white': #fff,
  )
);
$theme-colors: map-merge(
  $theme-colors,
  (
    'process-choice': #0a1f38,
  )
);
$theme-colors: map-merge(
  $theme-colors,
  (
    'dark-blue': #0a1f38,
  )
);
$theme-colors: map-merge(
  $theme-colors,
  (
    'black': #000,
  )
);
$theme-colors: map-merge(
  $theme-colors,
  (
    'active-task-list-item': lighten(#f5a734, 30%),
  )
);
$theme-colors: map-merge(
  $theme-colors,
  (
    'tank-green': #7ed321,
  )
);
$theme-colors: map-merge(
  $theme-colors,
  (
    'tank-yellow': #e4d400,
  )
);
$theme-colors: map-merge(
  $theme-colors,
  (
    'tank-red': #d0021b,
  )
);
$theme-colors: map-merge(
  $theme-colors,
  (
    'tank-light-red': #f56d7e,
  )
);
$theme-colors: map-merge(
  $theme-colors,
  (
    'oilworx': #e84c17,
  )
);
$theme-colors: map-merge(
  $theme-colors,
  (
    'oilworx-dark': #c93604,
  )
);
$theme-colors: map-merge(
  $theme-colors,
  (
    'sev1': #ce0b24,
  )
);
$theme-colors: map-merge(
  $theme-colors,
  (
    'sev2': #f5a623,
  )
);
$theme-colors: map-merge(
  $theme-colors,
  (
    'sev3': #65ab1b,
  )
);
$theme-colors: map-merge(
  $theme-colors,
  (
    'green': #65ab1b,
  )
);
$theme-colors: map-merge(
  $theme-colors,
  (
    'sales-lead-sev1': #28b596,
  )
);
$theme-colors: map-merge(
  $theme-colors,
  (
    'sales-lead-sev2': #2fd4b0,
  )
);
$form-label-font-weight: $font-weight-bold;
$input-bg: #f4f4f6;
$input-focus-bg: #f4f4f6;
$input-disabled-bg: $gray-300;
$form-select-bg: #f4f4f6;
$form-select-disabled-bg: $gray-300;
$enable-validation-icons: false;

$badge-font-size: 1rem;
$badge-font-weight: $font-weight-normal;
$input-height: 3.5rem;
$input-height-inner: 3.5rem;
$form-feedback-invalid-color: #f5a734;
$list-group-hover-bg: $white-hover;
$list-group-active-color: $body-color;
$list-group-active-bg: lighten(#f5a734, 30%);
$list-group-active-border-color: lighten(#f5a734, 30%);
// tank-colors
$pull-tank: #f4f4f6;
$pull-tank-border: #363636; //0a1f38
$fill-tank: #f7f4d2;
$fill-tank-border: #d4ceb2;
