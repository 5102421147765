html,
body {
  // font-size: 18px;
  // height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.cursor-pointer {
  cursor: pointer;
}

.no-wrap {
  white-space: nowrap;
}

.white-space-normal {
  white-space: normal;
}

.white-space-break-spaces {
  white-space: break-spaces;
}

.text-break-word {
  word-break: break-word;
}

// .typeahead {
//     .dropdown-menu {
//         width: 90%;

//         button {
//             min-height: 2.8125rem;
//         }
//     }
// }

.icon-xxs {
  font-size: 8px !important;
  position: relative !important;
  top: -2px !important;
}

.lh-normal {
  line-height: 1.5rem;
}

.pull-tank {
  background-color: $pull-tank;
  border-color: $pull-tank-border;
  border-width: 1px;
}

.fill-tank {
  background-color: $fill-tank;
  border-color: $fill-tank-border;
  border-width: 1px;
}

.min-width-0 {
  min-width: 0;
}

@-webkit-keyframes blinker {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.blink {
  text-decoration: blink;
  -webkit-animation-name: blinker;
  -webkit-animation-duration: 0.6s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
}

.min-height-unset {
  min-height: unset !important;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.datepicker-z-index {
  z-index: 2000 !important;
}
