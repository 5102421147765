@import './forms';
@import './grid';
@import './buttons';
@import './list-group';
@import './utilities';
@import './badge';
@import './modal';

.login-background-color {
  @extend .bg-dark-blue;
}

.bg-lite-nav-bar {
  @extend .bg-gray;
}

@each $color, $value in $theme-colors {
  .link-#{$color} {
    color: RGBA(var(--#{$prefix}#{$color}-rgb), var(--#{$prefix}link-opacity, 1))
      if($enable-important-utilities, !important, null);
    text-decoration-color: RGBA(var(--#{$prefix}#{$color}-rgb), var(--#{$prefix}link-underline-opacity, 0))
      if($enable-important-utilities, !important, null);

    @if $link-shade-percentage != 0 {
      &:hover,
      &:focus {
        $hover-color: if(
          color-contrast($value) == $color-contrast-light,
          shade-color($value, $link-shade-percentage),
          tint-color($value, $link-shade-percentage)
        );
        color: RGBA(#{to-rgb($hover-color)}, var(--#{$prefix}link-opacity, 1))
          if($enable-important-utilities, !important, null);
        text-decoration-color: RGBA(to-rgb($hover-color), var(--#{$prefix}link-underline-opacity, d))
          if($enable-important-utilities, !important, null);
      }
    }
  }
}
