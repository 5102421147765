.btn-w50-right {
  @extend .btn;
  @extend .btn-primary;
  @extend .w-50;
  @extend .py-4;
  @extend .fw-bold;
  @extend .d-inline-block;

  @include media-breakpoint-down(xs) {
    position: absolute;
    left: 50%;
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 0rem;
  }
}

.btn-xs-right {
  @include media-breakpoint-down(xs) {
    position: relative;
    left: 15px;
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 0rem;
  }
}

.btn-primary-xl {
  @include media-breakpoint-down(xs) {
    position: relative;
    left: 15px;
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 0rem;
  }
}

.float-button {
  right: 0 !important;
  left: unset !important;
  bottom: 75px !important;
  box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.24) !important;
  width: 64px;
  height: 64px;
  $zindex: $zindex-dropdown - 1;
  z-index: $zindex !important;
}

.btn {
  &.shadow {
    box-shadow: -1px 1px 6px 0 rgba(0, 0, 0, 0.3) !important;
  }

  &.rounded-circle {
    width: 64px;
    height: 64px;
  }
}

.btn-float {
  position: fixed;
  right: 0 !important;
  left: unset !important;
  box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.24) !important;
  $zindex: $zindex-dropdown - 1;
  z-index: $zindex !important;

  &.bottom-right {
    @extend .me-3;
    bottom: 110px;
  }

  &.bottom-left {
    @extend .ms-3;
    bottom: 110px;
    right: unset !important;
    left: 'unset' !important;
  }

  &.top-right {
    @extend .me-3;
    top: 90px;
  }
}

.btn-w50-left {
  @extend .btn;
  @extend .btn-link;
  @extend .w-50;
  @extend .py-4;
  @extend .fw-bold;
  @extend .d-inline-block;
}

.btn-solid-white,
.btn-solid-primary {
  @extend .btn;
  min-width: 8.75rem;
  min-height: 3rem;
}

.btn-solid-white {
  @extend .btn-light;
}

.btn-solid-primary {
  @extend .btn-primary;
}

.btn-outline-white,
.btn-outline-white-sm {
  @extend .btn;
  @extend .btn-outline-light;
}

.btn-outline-white {
  min-width: 8.75rem;
  min-height: 3rem;
}

.btn-outline-white-sm {
  min-width: 6.75rem;
}

.btn-icon {
  @extend .btn;
  @extend .py-4;
  @extend .btn-primary;
  @extend .w-50;
  @extend .mx-1;
}

.btn-transfer-filter-category {
  @extend .btn;
  @extend .py-3;
  @extend .btn-primary;
  @extend .w-25;
  @extend .me-3;
  @extend .mb-3;
}

.btn-process-choice {
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .w-100;
  @extend .btn;
  min-height: 2.8125rem;
  white-space: normal;
  @extend .text-start;
}

.btn-gray {
  color: $body-color;
}

.button-primary-solid {
  @extend .btn;
  min-width: 8.75rem;
  min-height: 3rem;
  @extend .btn-primary;
}

.button-sm {
  min-width: 6.75rem;
}

.btn-sm {
  min-width: 6.75rem;
  min-height: 2rem;
}

.btn-select {
  min-height: 2.8125rem;
  white-space: normal;
}

.btn-md {
  min-width: 8.75rem;
  min-height: 3rem;
}

.btn-md-h {
  min-height: 3rem;
}

.filter-button {
  background-color: $white;
  @extend .btn-outline-primary;
}

.btn-checkbox-primary {
  $background: map-get($theme-colors, 'gray');
  $border: map-get($theme-colors, 'gray');

  @include button-variant(
    $background,
    $border,
    $hover-background: darken($background, 7.5%),
    $hover-border: darken($border, 10%),
    $active-background: darken($primary, 10%),
    $active-border: darken($primary, 12.5%)
  );

  color: $input-color !important;

  &:active,
  &.active {
    color: white !important;
  }

  &:disabled,
  &.disabled {
    background-color: $input-disabled-bg;
    opacity: 1;
  }
}

.btn-white {
  $background: map-get($theme-colors, 'white');
  $border: map-get($theme-colors, 'white');
  @include button-variant(
    $background,
    $border,
    $white-hover,
    $hover-border: darken($background, 5%),
    $active-background: darken($background, 10%),
    $active-border: darken($background, 12.5%)
  );
}

.btn-xl {
  min-height: 4.625rem;

  @include media-breakpoint-down(xs) {
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 0rem;
    position: relative;
    left: 30px;
  }
}

.btn-transfer-fuel {
  min-width: 10.5rem;
}

.btn-outline-oilworx:hover {
  color: white !important;
}

.btn-oilworx,
.btn-oilworx:hover {
  color: white !important;
}
