.page-container {
  @extend .container;
  @extend .mt-3;
  padding-bottom: 150px !important;
  min-height: 80vh;
  padding-left: 15px !important;
  padding-right: 15px !important;

  @include media-breakpoint-up(md) {
    min-height: unset !important;
  }
}

.full-page-container {
  @extend .container-fluid;
  min-height: 100vh;
  padding-bottom: 150px !important;

  @include media-breakpoint-up(md) {
    padding-bottom: 50px !important;
  }
}

.above-bottom-nav-bar {
  bottom: 54px !important;

  @media (min-width: 321px) {
    bottom: 71px !important;
  }
}

.below-lite-nav-bar {
  top: 54px !important;

  @media (min-width: 321px) {
    top: 71px !important;
  }
}

.container.page {
  max-width: unset !important;
  padding-bottom: 10vh;
}
