.text-muted {
  opacity: 0.5 !important;
  color: inherit !important;
}

.text-wrap-pretty {
  text-wrap: pretty !important;
}

.text-wrap-balance {
  text-wrap: balance !important;
}

.pull-right {
  @extend .d-block;
  @extend .ms-auto;
}

.mt-6 {
  margin-top: 3.75rem;
}

.fixed-bottom {
  &.z-index {
    z-index: $zindex-fixed + 1 !important;
  }
}

.min-w-25 {
  min-width: 25% !important;
}

.min-w-50 {
  min-width: 50% !important;
}

.min-w-75 {
  min-width: 75% !important;
}

.min-w-100 {
  min-width: 100% !important;
}

.line-height-1 {
  line-height: 1rem;
}

.border-width-medium {
  border-width: medium !important;
}
